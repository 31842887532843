// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppointmentPage from './screens/Appointment/AppointmentPage.js';
import RecordPage from './screens/RecordSession/recordPage.js';
import ScribingPage from './screens/Scribing/ScribingPage.js';
import ScribingDonePage from './screens/Scribing/ScribingDonePage.js';
import VideoRecordingPage from './screens/VideoRecording/VideoRecordingPage.js';
import HistoryPage from './screens/History/HistoryPage.js';
import UploadPage from './screens/UploadRecording/UploadPage.js';
import FormPage from './screens/FormScreen/FormPage.js';
import LoginPage from './screens/Login/Login.js';
import SignUpPage from './screens/SignUp/SignUp.js';
import { SocketProvider } from './context/SocketContext.jsx';
import { AppointmentsProvider } from './context/AppointmentsContext.js';
import { AuthProvider } from './context/AuthContext.jsx';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

function App() {
  return (
    <AuthProvider>
      <AppointmentsProvider> 
        <SocketProvider>
          <Router>
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/" element={<LoginPage />} />

              {/* Private Routes */}
              <Route path="/appointments" element={<PrivateRoute> <AppointmentPage /> </PrivateRoute>} />
              <Route path="/record" element={<PrivateRoute> <RecordPage /> </PrivateRoute>} />
              <Route path="/scribing" element={<PrivateRoute> <ScribingPage /> </PrivateRoute>} />
              <Route path="/scribingdone" element={<PrivateRoute> <ScribingDonePage /> </PrivateRoute>} />
              <Route path="/videorecording" element={<PrivateRoute> <VideoRecordingPage /> </PrivateRoute>} />
              <Route path="/history" element={<PrivateRoute> <HistoryPage /> </PrivateRoute>} />
              <Route path="/upload" element={<PrivateRoute> <UploadPage /> </PrivateRoute>} />
              <Route path="/form" element={<PrivateRoute> <FormPage /> </PrivateRoute>} />
            </Routes>
          </Router>
        </SocketProvider>
      </AppointmentsProvider> 
    </AuthProvider>
  );
}

export default App;




